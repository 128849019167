<template>
  <v-container>
    <row
        class="mb-6"
        no-gutters
    >
      <v-col md="3" offset-md="3">
        <v-img
            :src="require('../assets/acceuil/Vitami_net.jpg')"
            contain
            max-height="150"
            min-width="400"
            offset-md="4"
        />
      </v-col>
    </row>
    <v-row>
      <v-col
          class="mb-5 mt-10"
          cols="12"
      >
        <v-row justify="center">
          <a
              href="/dashboard"
              class="subheading mx-3"
          >
            <v-img
                :src="require('../assets/acceuil/Plan-de-travail 44@2x-100.jpg')"
                class="my-3"
                contain
                height="200"
                width="300"
            />
          </a>
          <a
              href="/messagerie"
              class="subheading mx-3"
          >
            <v-img
                :src="require('../assets/acceuil/Plan-de-travail 40@2x-100.jpg')"
                class="my-3"
                contain
                height="200"
                width="300"
            />
          </a>
          <a
              href="/catalogue"
              class="subheading mx-3"
          >
            <v-img
                :src="require('../assets/acceuil/Catalogue@2x-100.jpg')"
                class="my-3"
                contain
                height="200"
                width="300"
            />
          </a>
        </v-row>
        <v-row justify="center">
          <a
              href="/documentation"
              class="subheading mx-3"
          >
            <v-img
                :src="require('../assets/acceuil/Bibliogra_fruit@2x-100.jpg')"
                class="my-3"
                contain
                height="200"
                width="300"
            />
          </a>
          <a
              href="/outils"
              class="subheading mx-3"
          >
            <v-img
                :src="require('../assets/acceuil/Plan-de-travail 42@2x-100.jpg')"
                class="my-3"
                contain
                height="200"
                width="300"
            />
          </a>
        </v-row>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",
}
</script>